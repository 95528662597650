.cartSingleItem {
    border-bottom: 1px solid var(--color-grey-4);
    margin-top: 14px;
    padding-bottom: 14px;
    align-items: flex-end;
    position: relative;
}
.cartSingleItem p {
    margin: 0px;
    margin-left: 30px;
    margin-right: 30px;
}
.cartItemImage {
    width: 102px;
    height: 119px;
}
.cartSingleItem > button {
    position: absolute;
    top: 5px;
    right: 0px;
    background: none;
    border: none;
    color: var(--color-grey-2);
    transition-duration: 0.3s;
    padding: 0px;
    margin: 0px;
}
.cartSingleItem > button:hover {
    color: black;
    cursor: pointer;
}
.qtySelectCont {
    margin-left: auto;
    margin-right: 0px;
}
.amount {
    margin-right: 0px !important;
}

/* mobile */
.mobile.cartSingleItem {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-bottom: unset;
}
.mobile .cartItemImage {
    min-width: 102px;
}
.mobile button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    color: var(--color-grey-2);
    transition-duration: 0.3s;
    padding: 0px;
    margin: 0px;
    height: 10px;
    line-height: 10px;
}
.mobile button:hover {
    color: black;
    cursor: pointer;
}
.mobile .cartItemInner {
    display: flex;
    flex-direction: column;
    align-items: space-between;
    position: relative;
    width: calc(100% - 102px);
    max-width:  calc(100% - 102px);
}
.mobile.cartSingleItem p {
    margin-bottom: 20px;
}
.mobile .qtySelectCont {
    margin-left: 30px;
}
.cartItemControlCont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
    height: 30px;
}