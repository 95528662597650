#gnar-consent-popup-outer {
    display: none;
}
#gnar-consent-popup-outer .inner {
    left: 0;
    top: 0;
    position: fixed !important;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    opacity: 0;
    transition: opacity 1.3s !important;
    max-height: 100vh;
    overflow-y: auto;
}
#gnar-consent-popup-outer.active {
    display: flex;
}
#gnar-consent-popup-outer.active .inner {
    opacity: 1 !important;
}

#gnar-consent-popup {
    background-color: white;
    padding: 20px;
    margin: 0px 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    max-width: 650px;
    margin: 20px;
}

#gnar-consent-popup h2 {
    margin: 0 0 10px;
    font-size: 24px;
}

#gnar-consent-popup p {
    margin: 0px;
    margin-bottom: 20px;
    font-size: 14px;
}

#gnar-consent-popup button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    font-size: 14px;
    width: 180px;
    white-space: nowrap;
}

#gnar-consent-popup button:hover {
    background-color: #0056b3;
}

button#deny-cookies,
button#more-info-btn {
    background-color: rgb(200,200,200);
    color: rgb(255,255,255);
}
button#more-info-btn {
    width: 260px !important;
}

.gs-button-cont {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    flex-wrap: wrap;
    transition-duration: 0.3s;
}
.gs-cookie-info-cont .more-info-btn {
    color: rgb(100,100,100);
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 20px;
    display: inline-block;
}
.gs-cookie-info-cont .more-info-btn:hover {
    cursor: pointer;
}

#gnar-consent-collapsible {
    height: 0px;
    overflow: hidden;
    transition: height 0.3s;
}
#gnar-consent-collapsible.active {
    height: 440px;
    max-height: 440px;
    overflow: auto;
}

.gs-cookie-info-cont {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(240, 240, 240);
    position: relative;
}
.gs-cookie-info-cont:last-of-type {
    border-bottom: none;
}
.gs-cookie-info-cont p {
    margin-bottom: 0px !important;
}
.gs-cookie-info-cont h4 {
    margin-top: 0px;
}
.gs-cookie-info-cont .customCheckbox {
    position: absolute;
    top: 18px;
    right: 0px;
}

#gnar-consent-popup .customCheckboxBox:after {
    background: #007bff;
}

@media (max-width: 768px) {

    .gs-button-cont {
        margin-top: 20px;
        flex-direction: column-reverse;
    }
    .gs-button-cont button {
        width: 100% !important;
    }
}