.addToCartBtn {
    background: var(--color-grey-4);
    border: none;
    border-radius: 17px;
    font-weight: 600;
    font-size: 20px;
    position: relative;
    height: 40px;
    width: 300px;
    border: none;
    overflow: hidden;
    z-index: 1;
}
.addToCartBtnOuter span {
    transition: 0.4s;
    color: rgb(30,30,30);
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);
    font-size: 20px;
    pointer-events: none;
    text-wrap: nowrap;
}
.outOfStock .addToCartBtn:disabled,
.outOfStock .addToCartBtn[disabled] {
    opacity: 0.5;
}
.outOfStock .addToCartBtn:disabled:hover,
.outOfStock .addToCartBtn[disabled]:hover {
    cursor: not-allowed;
}
.addToCartBtnOuter.cartAddEvent span {
    color: white;
}
.addToCartBtn:hover {
    cursor: pointer;
    opacity: 0.9;
}
.addToCartBtnOuter {
    margin-top: 20px;
    margin-bottom: 30px;
    margin-right: auto;
    padding: 0px;
    position: relative;
    overflow: hidden;
    border-radius: 17px;
    height: 40px;
    width: 300px;
    max-width: 300px;
}
.addToCartBtnOuter.cartAddEvent {
    height: 40px;
    width: 300px;
    max-width: 300px;
}
.addToCartBtnOuter:after {
    content: '';
    position: absolute;
    width: 400px;
    height: 400px;
    background: var(--color-dark-1);
    z-index: 2;
    transform: rotate(30deg);
    top: -50px;
    left: -400px;
    transition: ease-in-out 0.3s;
}
.addToCartBtnOuter.cartAddEvent:after {
    transform: rotate(45deg) translate(200px, -350px);
}
.addToCartForm {
    position: relative;
}
.checkoutBtn {
    position: absolute;
    top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    color: white;
    background: var(--color-dark-1);
    margin-top: 0px;
    margin-bottom: 30px;
    min-height: 40px;
    min-width: 300px;
}
.showCheckoutBtn {
    transition-duration: 0.3s;
    margin-bottom: 90px;
}
.outOfStockLabel {
    position: absolute;
    font-size: 12px;
    color: var(--color-grey-2);
    top: 5px;
    left: 150px;
}