.subscribeCont {
    background: var(--color-dark-1);
    padding-top: 70px;
    padding-bottom: 70px;
}
.subscribeCont * {
    color: white;
}
.subscribeCont h3 {
    color: var(--color-light-1);
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 900;
    margin-top: 0px;
    margin-right: 80px;
    min-width: 170px;
}
.subscribeCont h4 {
    max-width: 300px;
    margin-top: 5px;
}
.subscribeCont input[type="email"] {
    border: 0px;
    border-bottom: 1px solid var(--color-grey-2);
    min-width: 80px;
    background: none;
    color: white;
    font-size: 16px;
    padding: 10px 30px 10px 0px;
    margin-left: auto;
    margin-right: 10px;
    outline: none;
}

.subscribeBtn {
    background: var(--color-light-1);
    color: var(--color-main-1);
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    width: 340px;
    padding-top: 11px;
    padding-bottom: 11px;
    text-align: center;
    display: inline-block;
    text-wrap: nowrap;
    border: none;
}
.subscribeBtn:hover {
    opacity: 0.9;
    cursor: pointer;
}

.mobile .subscribeInnerCont {
    flex-direction: column;
}
.mobile form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.mobile input[type="email"] {
    margin-left: 0px;
    margin-bottom: 20px;
}
.mobile h3 {
    margin: 0px;
}
.mobile h4 {
    max-width: unset;
}