.contactForm {
    display: flex;
    flex-direction: column;
}
.contactForm label {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.contactForm input[type="submit"] {
    max-width: 300px;
}