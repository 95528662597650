.promoCode {
    display: flex;
    justify-content: space-between;
}
.promoCode input {
    flex-grow: 2;
    with: auto;
    border: 0px;
    padding: 7px 0px;
    border-bottom: 1px solid var(--color-grey-4);
    background: none;
}
.promoCode input:focus {
    outline: none;
}
.promoCode button {
    background: none;
    border: none;
}
.promoCode button:hover {
    cursor: pointer;
    opacity: 0.8;
}
.status {
    color: var(--color-grey-2);
    font-size: 12px;
    margin-top: 10px;
}