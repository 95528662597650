.cartCont {
    height: 100vh;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    background: white;
    border-left: 1px solid rgba(0,0,0,0.1);
    width: 50vw;
    transform: translatex(100vw);
    transition-duration: 1s;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 480px;
    z-index: 5;
}
.slide-exit-active {
    display: none;
}
.cart .slide-exit-active {
    display: block !important;
}
.cartCont.slide-enter-active,
.cartCont.slide-enter-done {
    display: block;
    width: 100vw;
    transform: translatex(0vw);
}
.cartCont.slide-exit-active {
    display: block;
}
.cartCont .orderSubTotals table,
.cartCont .orderSubTotals tr,
.cartCont .orderTotal table,
.cartCont .orderTotal tr {
    width: 100%;
}
.cartCont .orderSubTotals td {
    padding-bottom: 5px;
}
.cartCont .orderSubTotals td:nth-last-of-type(1) {
    text-align: right;
}
.cartCont .orderSubTotals,
.cartCont .rightSlideIn h3 {
    border-bottom: 1px solid var(--color-grey-4);
    padding: 0px 0px 30px 0px;
}
.cartCont .orderTotal {
    margin-bottom: 30px;
}
.cartCont .orderTotal td {
    vertical-align: bottom;
}
.cartCont .orderTotal td:first-of-type {
    line-height: 2.5em;
}
.cartCont .orderTotal td:nth-last-of-type(1) {
    text-align: right;
    font-size: 34px;
    font-weight: 600;
}
.cartCont .rightSlideIn .fade-2 {
    height: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
    max-height: 100vh;
    /* overflow-y: auto; */
}
.cartCont .bottomCont {
    margin-top: auto;
    margin-bottom: 0px;
}
.cartCont .inner {
    position: relative;
    max-height: 100vh;
    padding-top: 15px;
    padding-bottom: 15px;
    max-height: 100%;
    max-width: 100%;
    /* overflow-y: auto; */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-left: 30px;
    margin-right: 30px;
}
.cartCont .inner .content {
    width: 100%;
    max-width: 1000px;
}

/* mobile */
.mobileCartContOuter {
    overflow-y: auto;
}
.mobileCartCont {
    margin: auto;
    width: 100%;
    top: 100px;
    padding: unset;
    z-index: 0;
    transform: unset !important;
    position: fixed;
}

.mobileCartCont .cartItems > div {
    max-width: 100%;
}
.mobileCartCont .cartItems {
    max-height: 50vh;
    overflow-y: auto;
    z-index: 0;
    padding-bottom: 40px;
}
.mobileCartContOuter .bottomCont .orderTotal {
    margin-bottom: 40px;
}
.mobileCartContOuter .bottomCont {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.cartCheckoutHeaderCont {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--color-grey-4);
}
.cartCheckoutHeaderCont h1 {
    font-size: max(5vw, 18px);
    margin-left: 30px;
}