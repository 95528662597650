.collapsible-header {
    display: flex;
    justify-content: space-between;
}
.collapsible-header h3 {
    margin: 0px;
}
.collapsible-header:hover {
    cursor: pointer;
}
.collapsible-content {
    overflow: hidden;
    transition-duration: 0.5s;
}
.collapsible-content-open {
    margin-top: 0px;
}
.collapsible-content-inner {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
}
.collapsible-content-inner p {
    margin: 0px;
}
.collapsible-content-inner ul {
    margin: 0px;
    padding-left: 5px;
}