.singleCatProd, .loadingSingleProd {
    padding-top: 41.5%;
    position: relative;
    display: inline-block;
    width: 32%;
    max-width: 32%;
    margin-bottom: 2%;
}
.singleCatProdInner {
    position: absolute;
    top: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    border-radius: 36px;
    width: 100%;
    height: 100%;
    padding: 0%;
    box-sizing: border-box;
    overflow: hidden;
    background: white;
}
.singleCatProdInner .singleCatProdImg {
    max-width: 100%;
    z-index: 1;
    transition: transform 0.3s;
}
.singleCatProdImgOuter {
    width: 100%;
    max-width: 100%;
    height: 76%;
    overflow: hidden;
}
.singleCatProdImgInner {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.3s;
}
.singleCatProdInner:hover .singleCatProdImgInner {
    transform: scale(110%);
}
.singleCatProdInner p {
    z-index: 2;
    color: grey;
    font-size: 12px;
    margin: 0px;
    margin-bottom: 5px;
    text-align: right;
    line-height: 1.2em;
    padding-left: 20px;
}
.singleCatProdInner:hover .singleCatProdImg {
    transform: scale(110%);
}
.singleCatProdInner:hover .singleCatProdInfo {
    opacity: 1;
}
.singleCatProdInfo {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 10px 20px;
    transition: opacity 0.3s;
    opacity: 0.5;
    box-sizing: border-box;
}
.loadingSingleProd {
    background: var(--loading-back-1);
    border-radius: 36px;
}
.productName {
    max-height: 42px;
    overflow: hidden;
}

.mobile.singleCatProdInner {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.mobile .singleCatProd,
.mobile .loadingSingleProd {
    padding-top: 69%;
    width: 48%;
    max-width: 48%;
    margin-bottom: 4%;
}
.mobile .singleCatProdInner {
    border-radius: 26px;
}
.mobile .singleCatProdInfo {
    padding: 0px 10px 5px 10px;
}
.mobile .productName {
    padding-left: unset;
    max-height: 44px;
    overflow: hidden;
}

.singleCatProd .saleBanner {
    position: absolute;
    top: 13px;
    left: 13px;
    background: red;
    padding: 3px 12px 2px 12px;
    z-index: 2;
    border-radius: 15px;
}
.singleCatProd .saleBanner p {
    padding: unset;
    margin: unset;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
}

@media (max-width: 480px) {
    .singleCatProdImgOuter {
        height: 67% !important;
    }
    .product-swiper-related .singleCatProd {
        padding-top: 150% !important;
    }
}