.background {
    background: var(--color-grey-4);
    margin-bottom: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.outerCont {
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
}
.textCont {
    min-width: 50%;
}
.textCont h1,
.textCont p {
    color: white;
}
.noContent {
    min-height: 20vh;
    background: url('../../assets/img/catalog-hero/williams.svg');
    background-position: center center;
    background-size: cover;
}
.logoCont {
    width: 50%;
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
}
.logoCont img {
    width: 70%;
}
.mobile .outerCont {
    justify-content: center;
    flex-direction: column-reverse;
}
.mobile .logoCont {
    width: 100%;
    min-width: 100%;
    max-width: unset;
    justify-content: center;
}
.mobile .logoCont img {
    width: 100%;
}
.mobile .textCont {
    text-align: center;
    padding-bottom: 40px;
}
.mobile .textCont h1 {
    font-size: max(6vw, 24px);
}