.searchBar {
    position: relative;
}
.searchBar input {
    margin-left: 9px;
    border: 0px;
    border-bottom: 1px solid var(--color-grey-2);
    width: 120px;
}
.searchBar input:focus {
    outline: none;
}
.searchBar button {
    position: absolute;
    top: 2px;
    left: -25px;
    background: none;
    border: none;
}

.mobileCont {
    display: none;
    position: absolute;
    width: 100vw;
    top: 100px;
    left: 0px;
    background: white;
}
.mobileCont.mobileActive {
    display: flex;
}
.mobileCont form,
.mobileCont input {
    box-sizing: border-box;
    margin: 0px;
    width: 100%;
}
.mobileCont form {
    padding: 3px 50px 3px 5px;
}
.mobileCont input {
    border-bottom: 1px solid var(--color-grey-4);
}
.mobileCont button {
    position: absolute;
    right: 10px;
    left: unset;
}