.menu-teams-list ul {
    padding-right: 50px;
}
.menu-teams-list li a {
    transition: margin 0.2s ease-in-out;
}

.menu-teams-list.ferrari li.ferrari {
    background: #A6051A;
}
.menu-teams-list.ferrari li.ferrari a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.mercedes li.mercedes {
    background: #00A19C;
}
.menu-teams-list.mercedes li.mercedes a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.aston_martin li.aston_martin {
    background: #00665E;
}
.menu-teams-list.aston_martin li.aston_martin a {
    color: #CEDC00;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.mclaren li.mclaren {
    background: #FF8000;
}
.menu-teams-list.mclaren li.mclaren a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.red_bull li.red_bull {
    background: #121F45;
}
.menu-teams-list.red_bull li.red_bull a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.alpine li.alpine {
    background: #0078C1;
}
.menu-teams-list.alpine li.alpine a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.haas li.haas {
    background: #AEAEAE;
}
.menu-teams-list.haas li.haas a {
    color: #DA291C;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.williams li.williams {
    background: #00A3E0;
}
.menu-teams-list.williams li.williams a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.alpha_tauri li.alpha_tauri {
    background: #041F3D;
}
.menu-teams-list.alpha_tauri li.alpha_tauri a {
    color: #F1F3F4;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.alfa_romeo li.alfa_romeo {
    background: #A50F2D;
}
.menu-teams-list.alfa_romeo li.alfa_romeo a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.formula_one li.formula_one {
    background: #e10600;
}
.menu-teams-list.formula_one li.formula_one a {
    color: #FFFFFF;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.visa_cashapp_rb li.visa_cashapp_rb {
    background: #1434CB;
}
.menu-teams-list.visa_cashapp_rb li.visa_cashapp_rb a {
    color: #F1F3F4;
    margin-left: 20px !important;
    font-weight: 600;
}

.menu-teams-list.kick_sauber li.kick_sauber {
    background: #00E701;
}
.menu-teams-list.kick_sauber li.kick_sauber a {
    color: #F1F3F4;
    margin-left: 20px !important;
    font-weight: 600;
}