.languageSelect {
    background: none;
    border: 0px;
    color: white;
    right: 30px;
    width: 100%;
    border: 2px solid var(--color-dark-1);
    padding: 10px;
    border-radius: 4px;
}
.languageSelect option {
    color: var(--color-dark-1);
}
.languageSelect:focus {
    outline: none;
}