
.homepageTeamBanners {
    max-width: 100%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25)
}
.homepageTeamBannersMobile {
    max-width: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
}