.cont {
    padding: 20px;
    border-bottom: 1px solid var(--color-grey-4);
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}
.userDetailsCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}
.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatar img {
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
    border: 1px solid var(--color-grey-4);
}

/* mobile */
.mobile.cont {
    flex-direction: column;
    justify-content: center;
    border: none;
}
.mobile.cont h1 {
    text-align: center;
}
.mobile .userDetailsCont {
    flex-direction: column-reverse;
}