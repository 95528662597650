.customCheckbox {
    position: relative;
    padding-left: 35px;
    color: rgb(150,150,150);
    transition-duration: 0.3s;
    display: inline-block;
}
.customCheckbox input {
    display: none;
}
.customCheckboxBox {
    position: absolute;
    border: 1px solid rgb(200,200,200);
    min-width: 16px;
    min-height: 16px;
    top: 2px;
    left: 0px;
}
.isChecked {
    color: rgb(30,30,30);
}
.customCheckboxBox:after {
    transition-duration: 0.2s;
    opacity: 0;
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 2px;
    left: 2px;
    background: rgb(30,30,30);
}
.isChecked .customCheckboxBox:after {
    opacity: 1;
}