.shippingRates ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.shippingRates label {
    position: relative;
    display: block;
}
.shippingRates p {
    padding-right: 60px;
    padding-left: 30px;
}
.shippingRates span {
    position: absolute;
    right: 0px;
    top: 0px;
}
.shippingRates input {
    position: absolute;
    left: 0px;
    top: 3px;
}
.shippingRates {
    border-bottom: 1px solid var(--color-grey-4);
    padding: 0px 0px 30px 0px;
    margin-bottom: 30px;
}