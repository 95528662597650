/* Add to cart */
.locale-NL .addToCartBtn,
.locale-NL .addToCartBtnOuter,
.locale-NL .addToCartBtnOuter.cartAddEvent {
    width: 340px !important;
    max-width: 340px !important;
}
.locale-NL .goToCheckoutBtn {
    min-width: 340px !important;
    width: 340px !important;
    max-width: 340px !important;
}

/* Team catalog hero */
.catalog-hero.ferrari img {
    max-width: 50%;
}
.catalog-hero.visa_cashapp_rb img {
    max-width: 50%;
}
.catalog-hero.kick_sauber img {
    max-width: 70%;
}