.order-addresses {
    gap: 30px;
}
.order-addresses > div {
    width: 100%;
}
.order-addresses table {
    margin-top: 5px;
    margin-bottom: 30px;
}
.orderSubTotals {
    margin-bottom: 20px;
}
.orderSubTotals,
.orderSubTotals > table,
.orderTotal,
.orderTotal > table {
    width: 100%;
}
.orderTotal > table td:first-of-type,
.orderSubTotals > table td:first-of-type {
    font-weight: 600;
}
.orderTotal > table td:last-of-type,
.orderSubTotals > table td:last-of-type {
    text-align: right;
}

/* mobile */
.mobile.order-addresses {
    flex-direction: column;
    width: auto !important;
    position: relative;
    gap: 0px;
}
.mobile.order-addresses > div {
    max-width: 100%;
    box-sizing: border-box;
}
.mobile.order-recieved-cont h1 {
    text-align: center;
    line-height: 1.2em;
}