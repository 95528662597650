.checkoutCont {
    height: 100vh;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    background: white;
    border-left: 1px solid rgba(0,0,0,0.1);
    transition-delay: 0.5s;
    transition-duration: 0.5s;
    transform: translatex(100vw);
    padding-right: 480px;
    z-index: 6;
    width: 50vw;
    background: var(--color-grey-3);
}
.slide-exit-active {
    display: none;
}
.checkoutCont .slide-exit-active {
    display: block !important;
}
.checkoutCont.slide-enter-active,
.checkoutCont.slide-enter-done {
    display: block;
    width: 100vw;
    transform: translatex(0vw);
}
.checkoutCont.slide-exit-active {
    display: block;
}

.checkoutCont > .rightSlideIn {
    transition-delay: 0.5s;
    background: white !important;
    overflow-y: auto;
} 
.checkout .cartCont {
    width: 100% !important;
    transform: unset !important;
}
.checkout .cartCont .rightSlideIn {
    transition-delay: 0.3s;
    transition-duration: 0.5s;
    width: 100% !important;
}

.checkoutCont .orderSubTotals table,
.checkoutCont .orderSubTotals tr,
.checkoutCont .orderTotal table,
.checkoutCont .orderTotal tr {
    width: 100%;
}
.checkoutCont .orderSubTotals td {
    padding-bottom: 5px;
}
.checkoutCont .orderSubTotals td:nth-last-of-type(1) {
    text-align: right;
}
.checkoutCont .orderSubTotals,
.checkoutCont .rightSlideIn h3 {
    border-bottom: 1px solid var(--color-grey-4);
    padding: 0px 0px 30px 0px;
}
.checkoutCont .orderTotal {
    margin-bottom: 30px;
}
.checkoutCont .orderTotal td {
    vertical-align: bottom;
}
.checkoutCont .orderTotal td:first-of-type {
    line-height: 2.5em;
}
.checkoutCont .orderTotal td:nth-last-of-type(1) {
    text-align: right;
    font-size: 34px;
    font-weight: 600;
}
.checkoutCont .inner {
    position: relative;
    max-height: 100vh;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    max-height: 100%;
    max-width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}
.checkoutCont .inner .content {
    width: 100%;
    max-width: 1000px;
}

.checkout .delivery label {
    margin-top: 30px;
    display: inline-block;
    width: 100%;
}
.checkout input[type="text"],
.checkout input[type="email"],
.checkout input[type="password"],
.checkout select {
    border: none;
    border-bottom: 1px solid var(--color-grey-4);
    background: none;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 3px;
}
.checkout input[type="text"]:focus,
.checkout input[type="email"]:focus,
.checkout input[type="password"]:focus,
.checkout select:focus {
    outline: none;
    border-bottom: 1px solid rgb(0, 128, 32);
}

/* mobile */
.cartCheckoutHeaderCont {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid var(--color-grey-4);
}
.cartCheckoutHeaderCont h1 {
    font-size: max(5vw, 18px);
    margin-left: 30px;
}
.mobileCheckoutCont .checkoutFormOuter {
    margin-left: 20px;
    margin-right: 20px;
}