.orderItem {
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;
}
.image {
    min-width: 102px;
    height: 119px;
}
.qty {
    color: var(--color-grey-2);
}
.total {
    position: absolute;
    bottom: 0px;
    right: 0px;
    margin-bottom: 0px;
}
.qty {
    position: absolute;
    bottom: 0px;
    left: 0px;
    margin-bottom: 0px;
}
.details {
    width: auto;
    flex-grow: 2;
    margin-left: 20px;
    position: relative;
}