.product-swiper-related .swiper-slide {
    padding: 20px;
    box-sizing: border-box;
}
.product-swiper-related .singleCatProd {
    width: 100%;
    max-width: 100%;
    padding-top: 120%;
}

.mobile.productSlider .product-swiper-related .swiper-slide {
    padding: 10px 5px;
}