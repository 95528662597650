.toggle {
  background: none;
  border: 0px;
  color: white;
  position: absolute;
  right: 30px;
  top: 4px;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid white;
  transition-duration: 0.3s;
}
.toggle:hover {
  cursor: pointer;
  opacity: 0.8;
}
.modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  background: white;
  z-index: 99999999999;
  color: var(--color-dark-1);
  width: 400px;
  padding: 30px;
  grid-template-columns: 100%;
  gap: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  box-sizing: border-box;
}

.modal.open {
  display: grid;
}

.modal * {
  color: var(--color-dark-1) !important;
}

.backdrop {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  background: white;
  cursor: pointer;
  opacity: 60%;
  top: 0;
  left: 0;
}

.backdrop.open {
  display: block;
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.close img {
  width: 100%;
  height: 100%;
}