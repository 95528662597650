.desktop.catalogSidebar {
    min-width: 270px;
    width: 270px;
    padding-right: 70px;
    max-width: 270px;
}
.desktop.catalogSidebar .collapsible-content-open {
    margin-bottom: 20px;
}

