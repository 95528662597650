.collapsible {
  border: 2px solid var(--loading-dots);
  padding-inline: 15px;
  padding-block: 10px;
  border-radius: 4px;
  margin-bottom: 5px;
}
.answer ul {
  list-style: circle;
  padding-left: 20px;
}