.imageBoxOuter {
    padding-top: 100%;
    display: inline-block !important;
    width: 100%;
    box-sizing: border-box;
}
.imageBox {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
}
.imageBoxInner {
    border: 7px solid #FFFFFF;
    border-radius: 30px;
    background: var(--color-grey-3);
    box-sizing: border-box;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}

.mobile .imageBox  {
    padding: 10px 5px;
}
.mobile .imageBoxInner {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.product .mobile .imageBox {
    padding: 0px;
}
.product .mobile .imageBoxInner {
    border: 0px;
    border-radius: 0px;
    box-shadow: none;
}
.imageBoxLabel {
    position: absolute;
    bottom: 10%;
    left: 50%;
    translate: -50% 0%;
    text-align: center;
    font-size: 2.5vw !important;
    width: 100%;
}

@media only screen and (min-width: 769px) {
    .imageBoxLabel {
        font-size: 1.25vw !important;
        bottom: 7.5%;
    }
}