.goBack {
    padding-top: 100px;
    opacity: 0.8;
    display: inline-block;
    line-height: 10px;
    font-size: 16px;
    transition-duration: 0.3s;
}
.goBack:hover {
    opacity: 0.6;
    cursor: pointer;
}
.goBack img {
    transform: rotate(180deg) translateY(-1px);
    padding-left: 10px;
}