.myAccountCont {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 100%;
    position: relative;
}
.myAccountCont > div {
    display: inline-block;
}
.myAccountRightCol {
    display: inline-block;
    width: auto;
    max-width: calc(100% - 300px);
    position: relative;
}

/* mobile */
.mobile.myAccountCont {
    flex-direction: column;
}
.mobile.myAccountCont .myAccountRightCol {
    max-width: unset;
}