.emailLogin {
    margin-top: 20px;
    margin-bottom: 30px;
}
.emailLogin form {
    display: flex;
    flex-direction: column;
}
.loginCont h3 {
    padding-right: 40px;
}
.emailLogin h3 {
    font-weight: 400 !important;
    font-size: 12px;
    color: rgb(0,0,0,0.6);
}
.emailLogin > div > span {
    display: none;
}
.loginCont input[type="submit"] {
    margin-top: 20px;
}
.loginCont input[type="text"],
.loginCont input[type="email"],
.loginCont input[type="password"],
.loginCont select {
    border: none;
    border-bottom: 1px solid var(--color-grey-4);
    background: none;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 3px;
}
.loginCont input[type="text"]:focus,
.loginCont input[type="email"]:focus,
.loginCont input[type="password"]:focus,
.loginCont select:focus {
    outline: none;
    border-bottom: 1px solid rgb(0, 128, 32);
}
.socialLoginCont {
    margin-top: 20px;
    padding-top: 30px;
    border-top: 1px solid var(--color-grey-4);
    position: relative;
}
.socialLoginCont:before {
    content: 'or';
    position: absolute;
    top: -7px;
    line-height: 14px;
    font-size: 14px;
    color: var(--color-grey-4);
    left: 50%;
    width: 14px;
    transform: translateX(-17px);
    background: white;
    padding: 0 10px;
}