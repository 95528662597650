.headerCart {
    margin-bottom: 100px;
}
.mobile.headerCart {
    margin-bottom: 0px;
    position: absolute;
    right: 0px;
}
.backArrow {
    transform: rotate(180deg);
}