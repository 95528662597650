.cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-130px);
    box-sizing: border-box;
    padding: 12px;
}
.cont a,
.cont select {
    width: 48%;
    padding: 3px;
    box-sizing: border-box;
    max-width: 48%;
    text-align: center;
    border: 1px solid var(--color-grey-3); 
    background: white;
    border-radius: 26px;
    color: grey;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transition-duration: 0.3s;
}
.cont a:hover,
.cont select:hover {
    cursor: pointer;
    opacity: 0.95;
    color: rgb(30,30,30);
}
.cont select:focus {
    border: 1px solid var(--color-grey-3); 
}