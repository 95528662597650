.swiper {
    width: 100%;
}
.translated-up {
    transform: translateY(-30%);
}
.translated-up.teams {
    margin-bottom: -50px;
}
.header-prev {
    left: 2px;
}
.header-next {
    right: 2px;
}
.header-next,
.header-prev {
    position: absolute;
    top: 50%;
    z-index: 2;
    background: white;
    padding: 7px;
    padding-bottom: 0px;
    border-radius: 8px;
}
.header-next:hover,
.header-prev:hover {
    cursor: pointer;
    opacity: 0.8;
}
.hide-controls .header-prev,
.hide-controls .header-next {
    display: none;
}