.quantitySelect {
    border: 1px solid var(--color-grey-4);
    border-radius: 5px;
    margin-right: auto;
    max-width: 120px;
    display: flex;
}
.quantitySelect input {
    border: none;
    width: auto;
    max-width: 60px;
    text-align: center;
    color: var(--color-grey-1);
}
.quantitySelect a {
    width: 30px;
    text-align: center;
    color: var(--color-grey-1);
    opacity: 0.8;
}
.quantitySelect a:hover {
    cursor: pointer;
    opacity: 1;
}