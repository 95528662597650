.rightSlideIn {
    width: 480px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 0px;
    height: 100vh;
    right: 0px;
    padding: 60px;
    box-sizing: border-box;
    transform: translateX(480px);
    transition-duration: 1s;
}
.rightSlideIn.slide-exit-active {
    display: none;
}
.rightSlideIn.slide-exit-active {
    display: block !important;
}
.rightSlideIn.slide-enter-active,
.rightSlideIn.slide-enter-done {
    display: block;
    width: 480px;
    transform: translatex(0px);
}
.rightSlideIn.slide-exit-active {
    display: block;
}

/* mobile */
.mobileBottomCont {
    width: 100%;
    display: inline-block;
    margin: auto;
    margin-top: 60vh;
    top: unset;
    right: unset;
    height: unset;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    box-sizing: border-box;
    position: absolute;
    z-index: 2;
    background:var(--color-grey-3) !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}
