#express-payments {
    max-width: 360px;
    margin-top: 7px;
}
.desktop .cartCont .fade-1 {
    max-height: calc(100% - 150px);
    overflow-y: auto;
}
.desktop .checkoutCont .checkoutFormOuter {
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
    padding-bottom: 20px;
}
input[type=submit]:disabled {
    cursor: not-allowed;
}
.checkoutFormOuter .mobileCheckoutBottomCont {
    position: relative !important;
    margin-top: 30px !important;
    min-width: 100vw;
    margin-left: -20px;
    background: var(--color-grey-3);
}