.height100,
.height100 > div {
    height: 100%;
}

.homeHero {
    padding-top: 40%;
    max-height: 900px !important;
    background: #e5e5e3;
    background-size: cover;
    position: relative;
}
.homeHero h1 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 900;
    font-size: 46px;
    line-height: 1.2em;
}
.homeHero h3 {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 400;
    max-width: 75%;
    line-height: 1.7em;
}

.heroContent {
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 100%;
    height: 100%;
}
.heroContent > div {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
}
.heroContentInner {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 90%;
}
.heroImg {
    max-width: 100%;
    max-height: 70%;
    margin-inline: auto;
    margin-top: auto;
    transform: translateY(-10%);
    margin-bottom: auto;
}
.heroContent .leftCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
    min-width: 45%;
    padding-bottom: 10%;
}
.heroContent .rightCol {
    width: 55%;
    max-width: 55%;
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 1820px) {
    .homeHero {
        padding-top: unset;
        min-height: 750px;
        height: 750px;
    }
}


/* mobile */
.mobile.homeHero {
    padding-top: 0px;
    height: calc(120vw - 105px);
    max-height: unset !important;
}
.mobile.homeHero h1 {
    font-size: max(8vw, 30px);
}
.mobile .heroContentInner {
    flex-direction: column;
    justify-content: center;
}
.mobile .leftCol,
.mobile .rightCol {
    width: 100%;
    max-width: unset;
}
.mobile .leftCol h1 {
    margin-bottom: 10px;
}
.mobile .leftCol h1,
.mobile .leftCol h3 {
    text-align: center;
}
.mobile .leftCol h3 {
    margin-left: auto;
    margin-right: auto;
}