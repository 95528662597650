.headerTop {
    background: var(--color-dark-1);
    color: white;
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    box-sizing: border-box;
}
.headerTop p {
    margin: 0px;
    width: 100%;
    text-align: center;
    color: white;
}
.contentInner {
    position: relative;
    width: 100%;
}

/* tablet */
@media (max-width: 1024px) {
    .headerTop p {
        text-align: left;
    }
}

/* mobile */
.mobile {
    padding: 0px 15px;
}
.mobile select {
    right: 15px;
}
.mobile p {
    font-size: 10px;
    line-height: 1.2em;
    text-align: center;
}
.noticeBanner {
    background: rgb(230, 0, 0);
}