.breadcrumbs a {
    font-size: 12px;
    margin-right: 15px;
    color: var(--color-grey-1);
}
.breadcrumbs a:after {
    margin-left: 15px;
    content: '>';
}
.breadcrumbs a:last-of-type {
    margin-right: 0;
}
.breadcrumbs a:last-of-type:after {
    content: '';
}