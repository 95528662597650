/* theme vars */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');


/* variables */
:root {

    /* colors */
    --color-main-1: #131313;
    --color-main-2: #dbdbdb;
    --color-secondary-1: #b40606;
    --color-light-1: #ffffff;
    --color-dark-1: #242424;
    --color-grey-1: #808080;
    --color-grey-2: #909090;
    --color-grey-3: #F9F9F9;
    --color-grey-4: #E0E0E0;
    --loading-back-1: #F9F9F9;
    --loading-dots: #c5c5c5;

    /* content width */
    --content-width: 1300px;
    --narrow-content-width: 1100px;

    /* other global */
    --drop-shadow: 0px 10px 20px rgba(0,0,0,0.15);
}

/* SAFARI FIXES */
input {
    webkit-border-radius: 0px;
}

/* fonts */
* {
    font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    color: var(--color-dark-1);
}
h1 {
    text-transform: uppercase;
}
p, span, div, a {
    font-weight: 400;
    color: var(--color-dark-1);
}

/* base style */
body {
    margin: 0px;
    overflow-x: hidden;
}
a[href] {
    text-decoration: none;
}
ul {
    list-style: none;
}

/* helper classes */
.flexRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.flexColCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flexStart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.content {
    max-width: var(--content-width);
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
}
.vh100 {
    height: 100vh;
}
.page {
    margin-top: 155px;
    min-height: calc(100vh - 155px);
}
.shiftUp {
    transform: translatey(-100px);
}

/* product */
.productImage {
    background-size: cover;
    background-position: center center;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

/* buttons */
.btnDarkBig {
    background: var(--color-main-1);
    color: white;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 600;
    width: 340px;
    padding-top: 11px;
    padding-bottom: 11px;
    text-align: center;
    display: inline-block;
    text-wrap: nowrap;
}
.btnDarkBig:hover {
    opacity: 0.9;
    cursor: pointer;
}

.btnDarkMedium {
    background: var(--color-main-1);
    color: white;
    border-radius: 16px;
    font-size: 18px;
    font-weight: 600;
    padding: 11px auto;
    width: 100%;
    display: inline-block;
}
.btnDarkMedium:hover {
    opacity: 0.9;
    cursor: pointer;
}

.btnTextGrey {
    color: var(--color-grey-2);
    text-decoration: underline;
    font-size: 0.9rem;
}
.btnTextGrey:hover {
    opacity: 0.9;
    cursor: pointer;
}

.boxCont {
    border: 1px solid var(--color-grey-4);
    padding: 20px;
    margin-bottom: 30px;
}
.boxCont h4 {
    margin-top: 0px;
}

.loadingSpinner {
    border: 2px solid var(--color-grey-3);
    border-top: 2px solid rgb(50,50,50);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* mobile */
.mobile .content {
    padding-left: 15px;
    padding-right: 15px;
}
.mobile .page {
    margin-top: 105px;
    min-height: calc(100vh - 105px);
}
.mobile .pageMobileSearchActive {
    margin-top: 132px;
    min-height: calc(100vh - 132px);
}
.mobile h1 {
    font-size: max(8vw, 30px);
}
.mobile h2 {
    font-size: max(5vw, 18px);
}
.mobile h3 {
    font-size: max(4.5vw, 16px);
    line-height: 1.4em;
}
.mobile h4 {
    font-size: max(4.5vw, 16px);
}
.mobile h5 {
    font-size: max(4.5vw, 16px);
}
