header {
    background-color: var(--color-light-1);
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 5;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    height: 155px;
}
.outerCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 155px;
}
header nav a {
    color: var(--color-main-1);
}
header nav {
    flex-grow: 2;
    height: 55px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-left: 50px;
}
header nav ul {
    margin-top: auto;
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-start;
}
header nav ul li {
    margin-right: 50px;
}
header nav ul li:hover {
    cursor: pointer;
}
header a {
    margin-top: auto;
    margin-bottom: auto;
    height: auto;
}
.logo img {
    height: 50px;
    width: auto;
    max-width: 145px;
}
.iconCont {
    height: 55px;
    display: flex;
    align-items: flex-end;
}
.iconCont > div {
    margin-top: auto;
    margin-bottom: 0px;
    gap: 19px;
}
.iconCont a {
    max-height: 23px;
}
.headerMainOuter {
    background: white;
    margin-top: 20px;
    margin-bottom: 20px;
}


/* mobile */
.burger {
    width: 21px;
    height: 23px;
    color: grey;
}
.mobile .logo {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
}
.mobile .iconCont > div {
    margin-top: auto;
    margin-bottom: auto;
}
.mobile .logo {
    display: inline-block;
}
.mobile .logo img {
    max-width: 80px
}
.mobile .headerMainInner {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.mobile .leftCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.mobile .headerMainOuter {
    height: unset;
    margin-top: 0px;
    margin-bottom: 0px;
}
.mobile,
.mobile .outerCont {
    height: 105px;
}