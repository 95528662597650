.popupOuter {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 60;
    top: 0;
    left: 0;
}
.popupInner {
    position: relative;
    background: white;
    border-radius: 3px;
    padding: 20px 20px 20px 20px;
    box-shadow: 0px 20px 30px 10px rgba(0,0,0,0.1);
    max-width: 350px;
}
.closeCross {
    right: 20px;
    position: absolute;
    top: 15px;
}
.closeCross:hover {
    opacity: 0.8;
    cursor: pointer;
}

.popupOuterDisplay {
    display: none;
}
.popupOuterDisplay.active {
    display: block;
}
.popupOuter {
    transition-duration: 1s;
    opacity: 0;
}
.active .popupOuter {
    opacity: 1;
}
.facebook-login-button {
    margin-top: 13px;
    margin-bottom: 13px;
}
.facebook-login-button:hover {
    cursor: pointer;
    opacity: 0.9;
}