.validationErrors {
    border: 1px solid rgba(255, 136, 136, 0.4);
    background: rgba(255, 136, 136, 0.3);
    padding: 10px 20px;
}
.validationErrors p {
    color: red;
    font-size: 14px;
    margin: 2px 0px;
}
.controlsCont {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    padding-top: 30px;
    gap: 30px;
    max-width: 407px;
}
.countryNotListed {
    margin-top: 15px;
    font-size: 12px;
    color: var(--color-grey-1);
    font-weight: 100;
    display: block;
}
.agreeTerms a {
    text-decoration: underline;    
}