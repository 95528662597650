.catalogSidebar {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 100vh;
    min-width: 300px;
    width: 300px;
    max-width: 300px;
    background: var(--color-grey-3);
    z-index: 5;
}
.catalogSidebar ul {
    padding-left: 20px;
}
.backArrow {
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
}
.mainParentList,
.accountParentList {
    padding-left: 20px;
    padding-right: 20px;
}
.mainParentList li:hover,
.accountParentList li:hover {
    cursor: pointer;
    opacity: 0.9;
}
.mainParentList li {
    background: white;
    padding: 10px 10px;
    margin-bottom: 10px;
}
.accountParentList {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid var(--color-grey-4);
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 20px;
    margin-right: 20px;
}
.accountParentList li {
    padding-left: 10px;
}
.menuImage {
    height: 200px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center top;
}
.menuBack {
    z-index: 5;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    transition-duration: 0.3s;
    opacity: 0;
}
.closeBtn {
    position: absolute; 
    top: 0px;
    left: 330px;
    background: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuBack img {
    width: 20px;
    height: 20px;
}
.subMenuTitleCont {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-grey-4);
}
.subMenuTitleCont h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
}

.submenu ul li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.mobileLocaleSelect {
    border-top: 1px solid var(--color-grey-4);
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 10px;
    position: relative;
}
.mobileLocaleSelect p {
    font-weight: 600;
    margin-top: 0px;
}
.mobileLocaleSelect select {
    color: black !important;
    right: 0px;
}

.subMenuInner {
    margin-top: 10px;
    max-height: calc(100vh - 160px);
    overflow-y: auto;
}
.viewItemsBtn {
    position: absolute;
    bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: calc(100% - 40px);
    box-sizing: border-box;
}
.mobSearchTermCont {
    margin: 20px;
}