.fade-enter {
    opacity: 0;
    transform: translateY(30px);
}
.fade-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition-duration: 0.4s;
}
.inline-block {
    display: inline-block !important;
}
.fade-exit {
    opacity: 1;
    transform: translateY(0px);
}
.fade-exit-active {
    opacity: 0;
    transform: translateY(0px);
    transition-duration: 0.1s;
}

.fade-1, .fade-2 {
    transition-duration: 0.3s;
    transform: translatey(20px);
    opacity: 0;
}
.fade-2 {
    transition-delay: 0.2s;
}
.cart .cartCont.slide-enter-done .fade-1,
.cart .cartCont.slide-enter-done .fade-2{
    transform: translatey(0px);
    opacity: 1;
}
.cart .cartCont.slide-exit-active .fade-1,
.cart .cartCont.slide-exit-active .fade-2{
    transition-delay: 0s !important;
    transform: translatey(0px) !important;
}
.cart .slide-enter-done .fade-1,
.cart .slide-enter-done .fade-2,
.checkout .slide-enter-done .fade-1,
.checkout .slide-enter-done .fade-2 {
    transform: translatey(0px);
    opacity: 1;
}
.cart .slide-exit-active .cartCont .fade-1,
.cart .slide-exit-active .cartCont .fade-2,
.checkout .slide-exit-active .fade-1,
.checkout .slide-exit-active .fade-2 {
    transition-delay: 0s !important;
    transform: translatey(0px) !important;
}

.mobileMenu {
    transform: translateX(-100%);
    transition-duration: 0.3s;
}
.mobileMenuExitActive .mobileMenu,
.mobileMenuExitDone .mobileMenu {
    transform: translateX(-100%) !important;
}
.mobileEnterActive .mobileMenu,
.mobileMenuEnterDone .mobileMenu {
    transform: translateX(0%) !important;
}
.mobileMenuExitActive,
.mobileMenuExitDone {
    opacity: 0 !important;
}
.mobileEnterActive,
.mobileMenuEnterDone {
    opacity: 1 !important;
}

.mobileMenu .fade-exit {
    position: absolute;
    width: 100%;
}

.catalogSidebarMobile {
    transform: translateX(100%);
    transition-duration: 0.3s;
}
.mobileFiltersEnterActive .catalogSidebarMobile,
.mobileFiltersEnterDone .catalogSidebarMobile {
    transform: translateX(0%) !important;
}
.mobileFiltersExitActive .catalogSidebarMobile,
.mobileFiltersExitDone .catalogcatalogSidebarMobileSidebar {
    transform: translateX(100%) !important;
}
.mobileFiltersExitActive,
.mobileFiltersExitDone {
    opacity: 0 !important;
}
.mobileFiltersEnterActive,
.mobileFiltersEnterDone {
    opacity: 1 !important;
}

.catalogSidebar .fade-exit {
    position: absolute;
    width: 100%;
}


.fading-in {
    display: block;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}
.fading-out {
    display: block;
    opacity: 1;
    animation: fadeOut 0.3s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* DOTS FALLING LOADING */
.dots-cont {
    width: 100%;
    display: flex;
    justify-content: center;
}
.dots-cont p {
    position: absolute;
    top: 80px;
}
.dot-falling {
    margin-top: 15px;
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--loading-dots);
    color: var(--loading-dots);
    box-shadow: 9999px 0 0 0 var(--loading-dots);
    animation: dot-falling 1s infinite linear;
    animation-delay: 0.1s;
}
.dot-falling::before, .dot-falling::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-falling::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--loading-dots);
    color: var(--loading-dots);
    animation: dot-falling-before 1s infinite linear;
    animation-delay: 0s;
}
.dot-falling::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--loading-dots);
    color: var(--loading-dots);
    animation: dot-falling-after 1s infinite linear;
    animation-delay: 0.2s;
}

@keyframes dot-falling {
    0% {
        box-shadow: 9999px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%, 50%, 75% {
        box-shadow: 9999px 0 0 0 var(--loading-dots);
    }
    100% {
        box-shadow: 9999px 15px 0 0 rgba(152, 128, 255, 0);
    }
}
@keyframes dot-falling-before {
    0% {
        box-shadow: 9984px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%, 50%, 75% {
        box-shadow: 9984px 0 0 0 var(--loading-dots);
    }
    100% {
        box-shadow: 9984px 15px 0 0 rgba(152, 128, 255, 0);
    }
}
@keyframes dot-falling-after {
    0% {
        box-shadow: 10014px -15px 0 0 rgba(152, 128, 255, 0);
    }
    25%, 50%, 75% {
        box-shadow: 10014px 0 0 0 var(--loading-dots);
    }
    100% {
        box-shadow: 10014px 15px 0 0 rgba(152, 128, 255, 0);
    }
}