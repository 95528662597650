.f1-submenu ul {
    padding-left: 0px !important;
}
.f1-submenu .collapsible-header p {
    padding-left: 20px !important;
    margin: unset;
}
.f1-submenu .collapsible-content {
    padding-left: 40px;
}
.f1-submenu .collapsible-header > span {
    display: none;
}
.f1-submenu .mobile-menu-team.active {
    padding-top: 10px;
    padding-bottom: 10px;
}
.f1-submenu .mobile-menu-team a {
    color: white;
}
.f1-submenu .mobile-menu-team {
    position: relative;
}

.mobile-menu-team-logo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 70px;
    height: 30px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
}
.mobile-menu-team.active .mobile-menu-team-logo {
    opacity: 1;
}

/* Teams */
.mobile-menu-team.active.mercedes {
    background: #00A19C;
}
.mobile-menu-team.active.mercedes p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.red_bull {
    background: #121F45;
}
.mobile-menu-team.active.red_bull p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.aston_martin {
    background: #00665E;
}
.mobile-menu-team.active.aston_martin p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.ferrari {
    background: #A6051A;
}
.mobile-menu-team.active.ferrari p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.mclaren {
    background: #FF8000;
}
.mobile-menu-team.active.mclaren p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.alpine {
    background: #0078C1;
}
.mobile-menu-team.active.alpine p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.haas {
    background: #AEAEAE;
}
.mobile-menu-team.active.haas p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.williams {
    background: #005AFF;
}
.mobile-menu-team.active.williams p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.alpha_tauri {
    background: #2B4562;
}
.mobile-menu-team.active.alpha_tauri p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.formula_one {
    background: #E10600;
}
.mobile-menu-team.active.formula_one p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.kick_sauber {
    background: #00E701;
}
.mobile-menu-team.active.kick_sauber p {
    color: #FFFFFF;
    font-weight: 600;
}

.mobile-menu-team.active.visa_cashapp_rb {
    background: #1434CB;
}
.mobile-menu-team.active.visa_cashapp_rb p {
    color: #F1F3F4;
    font-weight: 600;
}
