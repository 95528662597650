.megaMenuCont {
    z-index: 3;
    display: flex;
    justify-content: center;
}
.megaMenu {
    width: 100%;
    height: 0px;
    max-width: var(--narrow-content-width);
    background: white;
    box-shadow: var(--drop-shadow);
    border-radius: 0px 0px 20px 20px;
    overflow: hidden;
    transition-duration: 0.5s;
}
.active {
    height: 440px !important;
}
.active.f1 {
    height: 635px !important;
}
.leftCont {
    position: relative;
    width: 60%;
    min-width: 60%;
    max-width: 60%;
    padding: 35px 50px;
    box-sizing: border-box;
}
.rightCont {
    position: relative;
    width: 40%;
    min-width: 40%;
    max-width: 40%;
    background: var(--color-grey-3);
    height: 100%;
    padding: 0px;
}
.leftCont > div {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 100%;
    box-sizing: border-box;
}
.listCont {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
}
.leftCont ul {
    margin: 0px;
    padding: 0px;
}
.leftCont ul li {
    padding-top: 10px;
    padding-bottom: 10px;
}
.leftCont ul li a {
    color: var(--color-grey-2);
}
.leftCont ul li:hover a {
    color: var(--color-dark-1);
}
.leftCont .leftCol,
.leftCont .midCol,
.leftCont .rightCol {
    width: 33%;
    max-width: 33%;
}
.leftCont .leftColHalf {
    width: 50%;
    max-width: 50%;
}

/* content animation */
.leftCont > div,
.rightCont > div {
    /* transition-delay: 0.2s; */
    max-width: 100%;
}
.midCol > ul {
    position: absolute;
    top: 65px;
}
.rightCont > div {
    position: absolute;
    top: 0px;
}
.menuImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}