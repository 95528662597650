.outer {
    background: var(--color-main-2);
    width: 300px;
    max-width: 300px;
}
.outer li:hover {
    cursor: pointer;
}

/* mobile */
.mobile.outer {
    background: unset;
    width: 100%;
    max-width: unset;
}
.mobile.outer ul {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--color-grey-4);
    padding-left: 5px;
    width: 100%;
    box-sizing: border-box;
}
.mobile.outer li {
    border: 1px solid var(--color-grey-4);
    border-bottom: 1px solid white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-bottom: -1px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
}