.catalog-hero.williams h1,
.catalog-hero.williams p {
  color: rgb(4,30,66);
}
.catalog-hero.mclaren h1,
.catalog-hero.mclaren p {
  color: var(--color-dark-1);
}
.catalog-hero.alpine h1,
.catalog-hero.alpine p {
  color: rgb(10,10,10);
}
.catalog-hero.haas h1,
.catalog-hero.haas p {
  color: rgb(10,10,10);
}

.catalog-hero h1 {
    max-width: 400px;
    line-height: 2.5rem;
    margin-bottom: 4px;
}
.catalog-hero p {
    margin-top: 0px;
    font-size: 16px;
}