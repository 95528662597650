.productOuter {
    padding-top: 30px;
}
.productImageSwiper {
    max-width: 50%;
}
.productImageSwiper img {
    max-width: 100%;
}
.productImageSwiper > div {
    transform: unset !important;
}
.cols {
    gap: 4%;
    box-sizing: border-box;
}
.cols > div {
    max-width: 48%;
    width: 48%;
    box-sizing: border-box;
}
h1 {
    font-size: 32px;
    font-weight: 400;
}
.singleProductInformation {
    border-top: 1px solid var(--color-grey-4);
    padding-top: 10px;
    padding-bottom: 10px;
}
.singleProductInformation h3 {
    font-size: 16px;
    font-weight: 400; 
}
.singleProductInformation > div > span {
    color: var(--color-grey-1);
}
.singleProductInformation > div {
    margin: 0px;
}
.singleProductInformation:last-of-type {
    border-bottom: 1px solid var(--color-grey-4);
    margin-bottom: 20px;
}
.attributeSelect {
    padding-left: 0px;
}
.attributeSelect ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    padding-left: 0px;
}
.attributeSelect a {
    border: 1px solid var(--color-grey-4);
    color: var(--color-grey-1);
    text-transform: uppercase;
    font-size: 14px;
    border-radius: 16px;
    min-width: 70px;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    text-align: center;
    opacity: 0.8;
    transition: 0.1s;
}
.attributeSelect a:hover {
    opacity: 1;
    cursor: pointer;
}
.attributeSelect .selected a {
    border: 1px solid black;
    color: white;
    background: black;
}
.attributeSelect .selected a:hover {
    opacity: 0.8;
}

.singleProductInformation table {
    width: 100%;
    margin-bottom: 20px;
}
.singleProductInformation table th {
    text-align: left;
}
.priceSuffix {
    color: var(--color-grey-2) !important;
    font-size: 12px;
}
.oldPrice {
    color: var(--color-grey-2) !important;
    margin-right: 10px;
    font-size: 12px;
    text-decoration: line-through;
}
.sku {
    color: var(--color-grey-2) !important;
    font-size: 12px;
}

/* mobile */
.mobile.productOuter {
    padding-top: 0px;
} 
.mobile .cols {
    display: inline-block !important;
    margin-top: 0px !important;
    max-width: 100vw;
}
.mobile .cols > div {    
    max-width: 100%;
    width: 100%;
}
.mobile .productImageSwiper {
    position: fixed;
}
.mobile .productImageSwiper {
    max-width: 100%;
}
.mobile .imageBox {
    padding: 0px !important;
}
.mobile .productDetails {
    margin-top: 95vw;
    background: white;
    z-index: 1;
    position: relative;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    padding: 10px 20px 30px 20px;
}
.mobile h1 {
    font-size: max(5vw, 18px);
}
.mobile .attributeSelect ul {
    margin-top: 20px;
    flex-wrap: wrap;
    max-width: 100%;
}