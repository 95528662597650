.contactDetails {
    display: inline-block;
}
.colCont {
    gap: 50px;
}
.colCont > div {
    width: 50%;
    max-width: 50%;
}
.contactPage {
    padding-top: 30px;
}

/* mobile */
.mobile .colCont {
    flex-direction: column !important;
}
.mobile .colCont > div {
    width: 100%;
    max-width: 100%;
}