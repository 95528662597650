.productCatListOuter {
    width: 100%;
    max-width: 100%;
    display: flex;
}
.productCatList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2%;
    margin-bottom: auto;
}
.productChunk {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2%;
    margin-bottom: auto;
}
.catalog .productCatList {
    transform: translatey(-100px);
}
.productCatList {
    margin-bottom: auto;
}
.singleCatProd {
    height: 0px;
}
.productCatListEmpty {
    text-align: center;
    width: 100%;
}
.productCatListEmpty p {
    color: var(--color-dark-1);
}
.search-term-cont {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}
.search-term-cont div {
    border: 1px solid var(--color-grey-4);
    background: var(--color-grey-3) !important;
    border-radius: 10px;
    padding: 0px 25px 0px 5px;
    position: relative;
}
.search-term-cont button {
    position: absolute;
    right: 0px;
    top: 3px;
    transform: rotate(45deg);
    border: none;
    background: none;
    color: var(--color-grey-2);
    font-size: 18px;
    line-height: 18px;
}
.search-term-cont button:hover {
    cursor: pointer;
}
.search-term-cont span {
    color: var(--color-grey-2) !important;
}
.mobile.productChunk {
    gap: 4%;
}
.mobile .productCatList {
    transform: translatey(-130px);
}