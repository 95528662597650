.logo img {
    max-width: 200px;
}
.footer {
    background: var(--color-grey-3);
    position: relative;
    width: 100%;
}
.upperCont {
    border-bottom: 1px solid rgba(0,0,0,0.4);
    padding-top: 100px;
    padding-bottom: 30px;
}
.bottomCont {
    padding-top: 30px;
    padding-bottom: 100px;
}
.logoCont {
    flex-grow: 2;
}
.upperCont h4 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.upperCont ul {
    padding-left: 0px;
}
.upperCont a {
    font-size: 12px;
    line-height: 1.2em;
}
.upperCont .col {
    width: 16.66%;
}
.copyright {
    margin-bottom: 0px;
}
.gnarLink {
    margin-top: 0px;
    opacity: 0.5;
    transition: 0.1s;
    font-size: 14px;
}
.gnarLink:hover {
    opacity: 0.7;
}
.companyInfo {
    margin: 0px;
    font-size: 12px;
}

/* mobile */
.mobile.footer .bottomCont {
    padding-bottom: 30px;
}