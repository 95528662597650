.action {
  padding-inline: 10px;
  padding-block: 5px;
  background: var(--color-main-1);
  color: #ffffff;
  border-radius: 4px;
}

.notfound {
  padding-top: 60px;
}