.reviews {
    background: var(--color-dark-1);
    padding-top: 70px;
    padding-bottom: 180px;
}
.reviews h3 {
    color: var(--color-light-1);
    font-size: 40px;
    text-transform: uppercase;
    font-weight: 900;
    margin-top: 0px;
    margin-right: 80px;
}
.review h4 {
    margin-top: 5px;
    color: white;
}
.review p {
    color: white;
    font-size: 14px;
}
.review .reviewDate {
    color: var(--color-grey-2);
    font-size: 10px;
    text-transform: uppercase;
}

/* mobile */
.mobile.reviews {
    padding-top: 50px;
    padding-bottom: 60px;
}
.mobile.reviews h3 {
    font-size: max(4.5vw, 16px);
}
.mobile.reviews h4 {
    font-size: max(4.5vw, 16px);
}